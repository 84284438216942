import {
    Box,
  Button,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  styles,
} from "../styles/main-styles";
import { ContactButton } from "./common/contact-button";
import { navigate } from "../router-config";
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { MobileNavigation } from "./website/mobile-navigation";
import React from "react";

export function LandingToolbar(
  {
    click,
    large = false
  }:{
    click?:()=>void;
    large?: boolean;
  }) { 
    
    const [showMobileMenu, setShowMobileMenu] = React.useState(false);

    let defaultStyle: any = {...styles.landingsToolbar.toolbar, zIndex:999, webkitBackdropFilter:"blur(0.5em)", backdropFilter:"blur(0.5em)", position:"fixed"}; // background:"rgba(35,39,47,0.5)", backdropFilter:"blur(0.5em)", zIndex:999
    defaultStyle.paddingTop = large? defaultStyle.marginTop * 1.5 : 0;
    defaultStyle.paddingBottom = large? defaultStyle.marginTop * 1.5 : 0;
    defaultStyle.marginTop = large? 0 : defaultStyle.marginTop;//TODO?

    const buttonStyle = {
      borderRadius: 12,
      textTransform: "none",
      pl:2,pr:2,
      color: "white"
    }

    return (
      <>
        <Toolbar style={defaultStyle}>
            <Box component={"img"} src="pa-logo.png" alt="" sx={styles.landingsToolbar.image} onClick={()=>navigate("/")}/>
            <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="body1" component="p" sx={{ display: { xs:"inherit", md: "inherit" } }} >Pixel Ape</Typography>
            </Box>              

            <Box sx={{ display: { xs: "none", md: "inherit" } }}>
              <Button sx={buttonStyle} onClick={()=>navigate("#services")}>Diensten</Button>
              <Button sx={{...buttonStyle}} onClick={()=>navigate("#assistants")}>AI Demo's</Button>
              <Button sx={buttonStyle} onClick={()=>navigate("#use-cases")}>AI Use Cases</Button>
              {/* <Button sx={{...buttonStyle, mr:2}}  onClick={()=>navigate("#about")}>Over Ons</Button> */}
              {
                click==null? <ContactButton /> : <ContactButton click={()=>click?.()}/>
              }
            </Box>          
           <Box sx={{ display: { xs:"inherit", md: "none" } }}>
              <IconButton sx={{ display: { xl: 'none', xs: 'inherit' } }} aria-label="side-menu" onClick={()=>setShowMobileMenu(true)}>
                  <DragHandleIcon />
              </IconButton>       
           </Box>
        </Toolbar>  
        <MobileNavigation show={showMobileMenu} onClose={()=>setShowMobileMenu(false)}/>
      </>

      );
}
