import { Component } from "react";
import { AssistantDataStore } from "../../data/data-store";
import { BaseChat } from "./base-chat";

export class Lea extends Component {

    render(){
        return (
            <BaseChat
                store={AssistantDataStore}
                logo={true}
                singlePage={true}
                mobileMenu={false}
                attachmentEnabled={true}
                chatTitle={"Prive gesprek"}
                chatTitleMobile={"Prive gesprek"}
                defaultMessageContent={this.defaultMessageContent()}
                actionContainer={<></>}
                temperature={0}
                systemMessages={this.buildSystemMessages()}
            />
        );
    }

    private defaultMessageContent(){
        return "Hey, ik ben een AI die klaarstaat om je te helpen met je vragen of om afbeeldingen te analyseren. Of je nu iets wilt weten of een plaatje hebt om te bespreken, ik sta voor je klaar. <br/><br/><b>Let op:</b> gesprekken en gegevens die we hier uitwisselen, worden niet opgeslagen of verwerkt buiten dit gesprek.";
    }

    private buildSystemMessages(){
        return [`Je bent een AI assistent.  
                Je taak is om zowel algemene vragen als foto-gerelateerde vragen zo goed mogelijk te beantwoorden.  
                Bij foto’s geef je een duidelijke beschrijving van wat je ziet of ga je in op specifieke vragen over de afbeelding.  
                Voor algemene vragen geef je een helder en behulpzaam antwoord, passend bij het onderwerp.`.trim(),
                `Je hebt een lichtelijk nonchalant, humoristisch en plagend karakter. Het karakter beinvloed geen feitelijke informatie`.trim(),
                `Je naam is Pietertje en je spreekt de persoonn aan met "Big Dawg" of "Mijn kleine Knuffelwolk van liefde"`.trim(),
];
    }
}