import React from 'react';
import { Box, Typography, IconButton, Container, Link, Snackbar } from '@mui/material';
import { Facebook, Twitter, Instagram, LinkedIn } from '@mui/icons-material';
import { FlipWords } from '../aceternity/flip-words';

export function Footer({color}: {color:string}){ //TODO h6 is niet in lijn met heading order: https://dequeuniversity.com/rules/axe/4.9/heading-order
  const currentYear = new Date().getFullYear();

  const [open, setOpen] = React.useState(false);

  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: 'auto',
        backgroundColor: color,
        color: (theme) => theme.palette.common.white,
      }}
    >
      <Container maxWidth="lg">
        <Typography variant="h6" component={"div"} align="center" gutterBottom>
            <FooterTitle/>
        </Typography>
        <Typography variant="subtitle1" align="center" color="inherit" component="p">
          © {currentYear} Pixel Ape Innovations. Alle rechten voorbehouden.
        </Typography>
        <Typography variant="subtitle2" align="center" color="inherit" component="p">
          KVK: 80679056
        </Typography>
        <Box display="flex" justifyContent="center" mt={2}>
          <IconButton onClick={()=>setOpen(true)} aria-label="Facebook" color="inherit">
            <Facebook />
          </IconButton>
          <IconButton onClick={()=>setOpen(true)} aria-label="Twitter" color="inherit">
            <Twitter />
          </IconButton>
          <IconButton onClick={()=>setOpen(true)} aria-label="Instagram" color="inherit">
            <Instagram />
          </IconButton>
          <IconButton onClick={()=>setOpen(true)} aria-label="LinkedIn" color="inherit">
            <LinkedIn />
          </IconButton>
        </Box>
        <Box display="flex" justifyContent="center" mt={2}>
          {/* <Link href="#" color="inherit" sx={{ mx: 1 }}>
            Privacybeleid
          </Link> */}
          <Link href="#" color="inherit" sx={{ mx: 1 }}>
            Algemene Voorwaarden
          </Link>
          {/* <Link href="#" color="inherit" sx={{ mx: 1 }}>
            Contact
          </Link> */}
        </Box>
      </Container>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={()=>setOpen(false)}
        message="Op dit moment zijn onze social media pagina's nog niet beschikbaar. Kom op een later moment terug."
      />
    </Box>
  );
};


function FooterTitle(){
    const words = ["efficiënter.","slimmer.","sneller.", "minder."];
    return (

        <div className="mx-auto font-normal text-neutral-300 text-center pl-4 pr-4">
          Werk<FlipWords className="text-accent" words={words} /><br />
        </div>
    );
}

//export default Footer;
